
.color {
  $this: '.color';

  &--text {
    color: map-get($theme-light--colors, 'text');

    @include darkTheme() {
      color: map-get($theme-dark--colors, 'text');
    }
  }

  &--primary {
    color: $color--primary !important;
  }

  &--grey1 {
    color: #444;
  }

  &--grey2 {
    color: map-get($theme-light--colors, 'tertiary');

    @include darkTheme() {
      color: map-get($theme-dark--colors, 'tertiary');
    }
  }

  &--success {
    color: $color--success !important;
  }

  &--error {
    color: $color--error !important;
  }
}
