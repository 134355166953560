/**
 * Transition
 */
.transition {

  // Vars.
  $this: '.transition';

  // Base styling

  /**
   * Elements
   */

  /**
   * Modifiers
   */

  // None
  //&--fade-up {
  //  pointer-events: none;
  //  opacity: 0;
  //  transform: translate(0px, 10px);
  //  @include transition('all', '0.2s');
  //  position: absolute;
  //
  //  &--visible {
  //    position: initial;
  //    pointer-events: initial;
  //    opacity: 1;
  //    transform: translate(0px, 0);
  //  }
  //}
}
