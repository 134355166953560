
hr, .hr {
  // Vars
  $this: '.hr';
  $hr__color: #91a7bb;

  // Base styling
  height: 1px;
  color: #91a7bb;
  background-color: #91a7bb;
  border: none;
}