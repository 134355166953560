
.flex {
  $this: '.flex';
  display: flex;

  &--direction-column {
    flex-direction: column;
  }

  &--direction-row {
    flex-direction: row;
  }

  &--align-items-center {
    align-items: center;
  }

  &--justify-content-center {
    justify-content: center;
  }

  &--justify-content-flex-end {
    justify-content: flex-end;
  }

  &--justify-content-space-between {
    justify-content: space-between;
  }

  &--direction-column-md {
    @include breakpoint('medium-up') {
      flex-direction: column;
    }
  }

}
