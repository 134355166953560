/**
 * Show
 */
.show {

  // Vars.
  $this: '.show';

  // Base styling

  /**
   * Elements
   */

  /**
   * Modifiers
   */

  // Transparent
  &--transparent {
    opacity: 0;
  }

  // None
  &--none {
    display: none;
  }

  // Medium up
  &--medium-up {
    @include breakpoint('small-only') {
      display: none !important;
    }
  }

  // Large up
  &--large-up {
    @include breakpoint('small-only') {
      display: none !important;
    }
    @include breakpoint('medium-only') {
      display: none !important;
    }
  }

  // Small only
  &--small-only {
    @include breakpoint('medium-up') {
      display: none !important;
    }
  }

  // Medium only
  &--medium-only {
    @include breakpoint('small-only') {
      display: none !important;
    }
    @include breakpoint('large-up') {
      display: none !important;
    }
  }

  // Large only
  &--large-only {
    @include breakpoint('small-only') {
      display: none !important;
    }
    @include breakpoint('medium-only') {
      display: none !important;
    }
    @include breakpoint('xlarge-only') {
      display: none !important;
    }
  }

  // Transition
  &--transition {
    @include transition();
  }
}
