
.display {
  $this: '.display';

  &--block {
    display: block;
  }

  &--inline-block {
    display: inline-block;
  }

  &--width-100 {
    width: 100%;
  }

  &--width-50 {
    width: 100%;
  }

}
